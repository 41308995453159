<template>
  <q-dialog v-model="opened" persistent>
    <q-card v-if="form">
      <q-card-section class="row items-center">
        <q-input
          class="col-12"
          v-model="form.ticker"
          type="text"
          label="Ativo"
        />
        <q-input
          class="col-12"
          v-model="form.weight"
          type="number"
          label="Peso"
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn label="Fechar" color="negative" v-close-popup />
        <q-btn label="Confirmar" color="positive" @click="confirm" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  data() {
    return {
      opened: false,
      form: null
    };
  },
  methods: {
    open(form) {
      if (form) {
        this.form = form;
      } else {
        this.form = {
          ticker: null,
          weight: 0
        };
      }
      this.opened = true;
    },
    confirm() {
      if (this.form.ticker && this.form.ticker.length > 0) {
        if (this.form.weight) {
          this.form.weight = parseFloat(this.form.weight);
        } else {
          this.form.weight = 0;
        }
        this.$emit("confirm", this.form);
        this.opened = false;
      }
    }
  }
};
</script>

<style></style>
