<template>
  <q-dialog v-model="opened" persistent>
    <q-card v-if="form">
      <q-card-section class="row items-center">
        <q-input
          class="col-12"
          v-model="form.title"
          type="text"
          label="Título"
        />
        <q-input
          class="col-12"
          v-model="form.urlImage"
          type="text"
          label="URL imagem"
        />
        <q-input
          class="col-12"
          v-model="form.weight"
          type="number"
          label="Peso"
        />
        <q-checkbox
          v-model="form.tickerIsOnB3"
          label="Ativos estão na B3"
          @input="onB3"
        />
        <q-checkbox
          v-model="form.itIsCrypto"
          label="Ativos são Cryptomoedas"
          @input="onCrypto"
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn label="Fechar" color="negative" v-close-popup />
        <q-btn label="Confirmar" color="positive" @click="confirm" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  data() {
    return {
      opened: false,
      form: null
    };
  },
  methods: {
    open(form) {
      if (form) {
        this.form = form;
      } else {
        this.form = {
          title: null,
          urlImage: null,
          weight: 0,
          decimalPlaces: 0,
          itIsCrypto: false,
          tickerIsOnB3: false
        };
      }
      this.opened = true;
    },
    confirm() {
      if (this.form.title && this.form.title.length > 3) {
        if (this.form.weight) {
          this.form.weight = parseFloat(this.form.weight);
        } else {
          this.form.weight = 0;
        }

        if (this.form.tickerIsOnB3) {
          this.form.decimalPlaces = 0;
        } else if (this.form.itIsCrypto) {
          this.form.decimalPlaces = 8;
        } else {
          this.form.decimalPlaces = 4;
        }
        this.$emit("confirm", this.form);
        this.opened = false;
      }
    },
    onB3() {
      if (this.form.tickerIsOnB3) {
        this.form.itIsCrypto = false;
      }
    },
    onCrypto() {
      if (this.form.itIsCrypto) {
        this.form.tickerIsOnB3 = false;
      }
    }
  }
};
</script>

<style></style>
