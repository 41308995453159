<template>
  <div
    class="col-12 col-sm-5 col-md-4 col-lg-3 col-xl-2 cursor-pointer shadow-5 q-ma-sm"
    :class="cardClass"
    @click="$emit('cardClick', card)"
  >
    <div class="q-pa-sm " style="height: 11vh;">
      <div class="row justify-between">
        <img
          v-if="card.urlImage"
          class="col-3"
          :src="card.urlImage"
          style="height: 7vh;"
        />
        <div class="col-7">
          <span class="text-h6">{{ card.title }}</span>
        </div>
        <div class="col-1">
          <q-btn
            round
            dense
            size="sm"
            flat
            color="primary"
            icon="edit"
            @click="$emit('editCard', card)"
          />
          <q-btn
            round
            dense
            size="sm"
            flat
            color="negative"
            icon="delete"
            @click="$emit('removeCard', card)"
          />
        </div>
      </div>
      <div class="row justify-between">
        <span class="col-grown"
          ><strong>Total Ativos:</strong>
          {{
            formatString(card.tickers ? card.tickers.length : 0, "number", 0)
          }}</span
        >
        <span class="col-grown"
          ><strong>Peso:</strong>
          {{ formatString(card.weight ? card.weight : 0, "number") }}</span
        >
        <span class="col-grown"
          ><strong>% Part:</strong>
          {{ formatString(participation, "number") }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import formatString from "@/helpers/formatString";
export default {
  props: {
    card: {
      type: Object,
      required: true
    },
    totalWeight: {
      type: Number,
      required: true
    },
    cardClass: {
      type: String,
      require: false
    }
  },
  methods: {
    formatString
  },
  computed: {
    participation() {
      if (this.totalWeight) {
        return ((this.card.weight || 0) / this.totalWeight) * 100;
      }
      return 0;
    }
  }
};
</script>

<style></style>
