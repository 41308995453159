<template>
  <div class="row">
    <div
      v-if="cards"
      class="row q-col-gutter-sm justify-evenly"
      style="width: 96vw; max-height: 30vh; min-height: 11vh; overflow-y: auto;"
    >
      <Card
        :card-class="selectedCard === card ? 'bg-grey-6' : ''"
        v-for="card in cards"
        :key="card.id"
        :card="card"
        @editCard="card => $emit('editCard', card)"
        @removeCard="card => $emit('removeCard', card)"
        @cardClick="cardClick"
        :totalWeight="totalWeight"
      />
    </div>
  </div>
</template>

<script>
import Card from "./Card";
export default {
  props: {
    cards: {
      type: Array || null,
      required: true
    }
  },
  components: {
    Card
  },
  data() {
    return {
      selectedCard: null
    };
  },
  computed: {
    totalWeight() {
      return this.cards.reduce(
        (acc, card) => (acc += parseFloat(card.weight)),
        0
      );
    }
  },
  methods: {
    cardClick(card) {
      this.selectedCard = card;
      this.$emit("cardClick", card);
    }
  }
};
</script>

<style></style>
