<template>
  <q-dialog v-model="opened">
    <q-card :class="{ Table: synchronizedToday }">
      <div v-if="!synchronizedToday">
        <q-card-section class="row items-center">
          <span class="text-caption text-red"
            >O seus dados digitados abaixo serão utilizados somente para conexão
            com a CEI. Em nenhum momento seu CPF/CNPJ e/ou a sua senha serão
            armazenados em qualquer lugar por este aplicativo, seja na nuvem ou
            em qualquer lugar do seu dispositivo, como cookies ou
            localstorage.</span
          >
        </q-card-section>
        <q-card-section class="row justify-between">
          <q-input
            class="col-grown"
            dense
            type="text"
            label="CPF/CNPJ"
            v-model="id"
          />
          <q-input
            class="col-grown"
            dense
            type="password"
            label="Senha"
            v-model="password"
          />
          <q-btn
            class="col-grown"
            dense
            color="primary"
            icon="sync"
            label="Sinronizar"
            @click="onGetCEI"
          />
        </q-card-section>
      </div>
      <div v-else>
        <q-card-section>
          <div class="text-center text-bold">
            Para replicar os dados da CEI para a sua carteira no MyPilas, basta
            clicar duas vezes na linha.
          </div>
          <div class="text-center text-bold text-red">
            Porem, tenha atenção. Todos os dados do Ativo na carteira do MyPilas
            serão sobrescritos pelos dados da CEI, caso ele já exista na sua
            carteira.
          </div>
        </q-card-section>
        <q-card-section>
          <Datatable
            v-if="SynchronizeCEIData"
            :data="SynchronizeCEIData"
            :columns="columnsCEIData"
            @row-dblclick="rowDblclick"
          />
        </q-card-section>
      </div>
    </q-card>
    <TransactionsList ref="TransactionsList" />
  </q-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { format } from "date-fns";

import formatString from "@/helpers/formatString";
import Datatable from "@/components/Datatable";
import TransactionsList from "./TransactionsList";
import SelectGroupAndWeight from "./SelectGroupAndWeight";

export default {
  components: {
    Datatable,
    TransactionsList
  },
  data() {
    return {
      opened: false,
      id: null,
      password: null,
      columnsCEIData: [
        {
          name: "ticker",
          field: "ticker",
          label: "Ativo",
          align: "left",
          sortable: true
        },
        {
          name: "tickerTypeTitle",
          field: "tickerTypeTitle",
          label: "Grupo de Ativos",
          align: "left",
          sortable: true
        },
        {
          name: "quantity",
          field: "quantity",
          format: val => `${formatString(val, "number")}`,
          label: "Qtde. CEI",
          align: "left",
          sortable: true
        },
        {
          name: "quantityBase",
          field: "quantityBase",
          format: val => `${formatString(val, "number")}`,
          label: "Qtde. Carteira",
          align: "left",
          sortable: true
        },
        {
          name: "lastDateTransaction",
          field: "lastDateTransaction",
          format: val => `${formatString(val, "date")}`,
          label: "Dt. Ult.Tran. CEI",
          align: "left",
          sortable: true
        },
        {
          name: "lastPurchaseBase",
          field: "lastPurchaseBase",
          format: val => `${formatString(val, "date")}`,
          label: "Dt. Ult.Tran. Carteira",
          align: "left",
          sortable: true
        },
        {
          name: "transactions",
          field: row => row.transactions.length,
          format: val => `${formatString(val, "number")}`,
          label: "Qtde. Trans. CEI",
          align: "left",
          sortable: true
        },
        {
          name: "transactionsBase",
          field: row => row.transactionsBase.length,
          format: val => `${formatString(val, "number")}`,
          label: "Qtde. Trans. Carteira",
          align: "left",
          sortable: true
        },
        {
          label: "Lsita Transações CEI - Carteira",
          name: "actions",
          align: "center",
          actions: [
            {
              icon: "list",
              color: "blue",
              tooltip: "Lista Transações CEI",
              handler: row => this.openList(row.transactions)
            },
            {
              icon: "list",
              color: "blue",
              tooltip: "Lista Transações Carteira",
              handler: row => this.openList(row.transactionsBase)
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapState("Tickers", ["CEIlastSyncDate", "tickersType"]),
    ...mapGetters("Tickers", ["SynchronizeCEIData"]),
    synchronizedToday() {
      const today = format(new Date(), "yyyyMMdd");
      return this.CEIlastSyncDate && this.CEIlastSyncDate >= today;
    }
  },
  methods: {
    ...mapActions("Tickers", ["getCEI", "updateTickerTransactions"]),
    formatString,
    open() {
      this.id = null;
      this.password = null;
      this.getCEI();
      this.opened = true;
    },
    async onGetCEI() {
      if (
        !this.id ||
        this.id.trim().length <= 0 ||
        !this.password ||
        this.password.trim().length <= 0
      ) {
        this.$q.notify({
          message: "Informe o seu CPF/CNPJ e a senha.",
          color: "orange",
          position: "top",
          icon: "announcement"
        });
        return;
      }
      this.$q.loading.show({
        message:
          '<b>Atualizando dados da CEI.</b><br/><span class="text-primary">Aguarde...</span>'
      });
      try {
        await this.getCEI({ id: this.id, password: this.password });
        this.$q.loading.hide();
        this.id = null;
        this.password = null;
      } catch (error) {
        this.$q.loading.hide();
        this.$q.notify({
          message: error.message,
          color: "red",
          position: "top",
          icon: "announcement"
        });
      }
    },
    confirm() {
      this.$emit("confirm");
      this.opened = true;
    },
    openList(transactions) {
      this.$refs.TransactionsList.open(transactions);
    },
    rowDblclick(_, row) {
      this.$q
        .dialog({
          title: `Confirma a sincronização do ativo ${row.ticker}?`,
          ok: {
            push: true,
            color: "positive",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "negative",
            label: "Não"
          }
        })
        .onOk(async () => {
          if (row.tickerTypeId) {
            this.$q.loading.show({
              message:
                '<b>Aguarde atualizando ativo.</b><br/><span class="text-primary">Aguarde...</span>'
            });
            await this.updateTickerTransactions({
              transactions: row.transactions,
              tickerTypeId: row.tickerTypeId,
              ticker: row.ticker,
              insertNewTicker: {
                ticker: row.ticker,
                weight: 0
              }
            });
            this.$q.loading.hide();
          } else {
            this.$q
              .dialog({
                component: SelectGroupAndWeight,
                ticker: row.ticker,
                tickersType: this.tickersType,
                parent: this
              })
              .onOk(async data => {
                this.$q.loading.show({
                  message:
                    '<b>Aguarde atualizando ativo.</b><br/><span class="text-primary">Aguarde...</span>'
                });
                await this.updateTickerTransactions({
                  transactions: row.transactions,
                  tickerTypeId: data.tickerType.value,
                  ticker: row.ticker,
                  insertNewTicker: {
                    ticker: row.ticker,
                    weight: data.weight
                  }
                });
                this.$q.loading.hide();
              });
          }
        });
    }
  }
};
</script>

<style scoped>
.Table {
  min-width: 80vw;
  max-width: 80vw;
}
</style>
