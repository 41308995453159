<template>
  <div class="q-pa-md">
    <div class="row justify-between q-mb-lg">
      <q-btn
        class="col-grown"
        color="primary"
        icon="add"
        label="Adicionar Grupo de Ativo"
        @click="openTickerForm"
      />
      <q-btn
        class="col-grown"
        color="primary"
        icon="sync"
        label="Sincronizar CEI"
        @click="openSynchronizeCEI"
      />
    </div>
    <TickersTypeList
      :cards="tickersType"
      @editCard="editCard"
      @removeCard="removeCard"
      @cardClick="cardClick"
    />
    <TickersList
      class="q-mt-lg"
      v-if="tickerTypeSelected"
      :tickerType="tickerTypeSelected"
      @confirmTicker="cardClick"
    />
    <TickerTypeForm ref="TickerTypeForm" @confirm="confirmTickerTypeForm" />
    <SynchronizeCEI ref="SynchronizeCEI" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import TickersTypeList from "./TickersTypeList";
import TickerTypeForm from "./TickerTypeForm";
import TickersList from "./TickersList";
import SynchronizeCEI from "./SynchronizeCEI";
export default {
  components: {
    TickersTypeList,
    TickerTypeForm,
    TickersList,
    SynchronizeCEI
  },
  data() {
    return {
      tickerTypeSelected: null
    };
  },
  computed: {
    ...mapState("Tickers", ["tickersType"])
  },
  methods: {
    ...mapActions("Tickers", [
      "addTickerType",
      "updateTickerType",
      "deleteTickerType"
    ]),
    openTickerForm() {
      this.$refs.TickerTypeForm.open();
    },
    async confirmTickerTypeForm(tickerType) {
      if (tickerType.id) {
        this.updateTickerType(tickerType);
      } else {
        await this.addTickerType(tickerType);
      }
    },
    editCard(tickerType) {
      this.$refs.TickerTypeForm.open({ ...tickerType });
    },
    removeCard(card) {
      this.$q
        .dialog({
          title: `Deseja realmente excluir o Grupo de Ativo ${card.title}?`,
          message:
            "Com esta ação todos os ativos amarrados a ele também serão excluídos.",
          ok: {
            push: true,
            color: "positive",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "negative",
            label: "Não"
          },
          persistent: true
        })
        .onOk(() => {
          this.deleteTickerType(card);
        });
    },
    cardClick(card) {
      this.tickerTypeSelected = card;
    },
    openSynchronizeCEI() {
      if (!this.tickersType || this.tickersType.length <= 0) {
        this.$q.notify({
          message:
            "Você precisa ter pelo menos um Grupo de Ativo criado para realizar a sicronização",
          color: "orange",
          position: "top",
          icon: "announcement"
        });
        return;
      }
      this.$refs.SynchronizeCEI.open();
    }
  }
};
</script>

<style></style>
