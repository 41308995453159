<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin" style="width: 200px">
      <q-card-section>
        <div class="text-h6 text-bold">Ativo {{ ticker }}</div>
      </q-card-section>
      <q-card-section>
        <q-select
          v-model="tickerType"
          :options="tickersTypePrepared"
          label="Grupo de Ativos"
        />
      </q-card-section>
      <q-card-section>
        <q-input v-model="weight" type="number" label="Peso" />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          size="sm"
          color="negative"
          label="Fechar"
          @click="onCancelClick"
        />
        <q-btn
          v-if="tickerType"
          size="sm"
          color="positive"
          label="Confirmar"
          @click="onOKClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    ticker: {
      type: String,
      required: true
    },
    tickersType: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tickerType: null,
      weight: 0
    };
  },
  computed: {
    tickersTypePrepared() {
      return this.tickersType
        .filter(item => item.tickerIsOnB3)
        .map(item => {
          return {
            label: item.title,
            value: item.id
          };
        });
    }
  },
  methods: {
    show() {
      this.tickerType = null;
      this.weight = 0;
      this.$refs.dialog.show();
    },
    hide() {
      this.$refs.dialog.hide();
    },
    onDialogHide() {
      this.$emit("hide");
    },
    onOKClick() {
      this.$emit("ok", {
        weight: parseFloat(this.weight),
        tickerType: this.tickerType
      });
      this.hide();
    },
    onCancelClick() {
      this.hide();
    }
  }
};
</script>
