<template>
  <div>
    <Datatable
      :title="`${tickerType.title}`"
      :data="preparedTickers"
      :columns="columns"
      height="40vh"
      :actions="actionsTickersTable"
    />
    <TickerForm ref="TickerForm" @confirm="confirmTicker" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Datatable from "@/components/Datatable";
import formatString from "@/helpers/formatString";
import TickerForm from "./TickerForm";
export default {
  components: {
    Datatable,
    TickerForm
  },
  props: {
    tickerType: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      columns: [
        {
          name: "actions",
          align: "center",
          actions: [
            {
              icon: "edit",
              color: "primary",
              handler: ticker => this.openTickerForm(ticker)
            },
            {
              icon: "delete",
              color: "negative",
              handler: ticker => this.deleteTicker(ticker)
            }
          ]
        },
        {
          name: "ticker",
          label: "Ativo",
          field: "ticker",
          align: "left",
          sortable: true
        },
        {
          name: "weight",
          label: "Peso",
          field: "weight",
          align: "right",
          format: val => `${formatString(val, "number")}`,
          sortable: true
        },
        {
          name: "participation",
          label: "% Part.",
          field: "participation",
          align: "right",
          format: val => `${formatString(val, "number")}`,
          sortable: true
        }
      ],
      actionsTickersTable: [
        {
          color: "primary",
          icon: "add",
          class: "q-mr-sm",
          label: "Adiciona",
          handle: () => {
            this.openTickerForm();
          }
        }
      ]
    };
  },
  computed: {
    preparedTickers() {
      if (!this.tickerType.tickers) {
        return [];
      }
      const totalWeight = Object.values(this.tickerType.tickers).reduce(
        (acc, ticker) => (acc += ticker.weight),
        0
      );
      return Object.values(this.tickerType.tickers).map(ticker => {
        return {
          ...ticker,
          participation: (ticker.weight / totalWeight) * 100
        };
      });
    }
  },
  methods: {
    ...mapActions("Tickers", ["updateTickerType"]),
    formatString,
    openTickerForm(ticker) {
      this.$refs.TickerForm.open(ticker);
    },
    confirmTicker(ticker) {
      const payload = { ...this.tickerType };
      if (!payload.tickers) {
        payload.tickers = [];
      }
      ticker.ticker = ticker.ticker.toUpperCase();
      const position = payload.tickers.findIndex(
        item => item.ticker === ticker.ticker
      );
      if (position >= 0) {
        payload.tickers[position] = ticker;
      } else {
        payload.tickers.push(ticker);
      }

      this.updateTickerType(payload);
      this.$emit("confirmTicker", payload);
    },
    deleteTicker(ticker) {
      this.$q
        .dialog({
          title: `Deseja realmente excluir o Ativo ${ticker.ticker}?`,
          ok: {
            push: true,
            color: "positive",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "negative",
            label: "Não"
          },
          persistent: true
        })
        .onOk(() => {
          const payload = { ...this.tickerType };
          if (!payload.tickers) {
            payload.tickers = [];
          }
          ticker.ticker = ticker.ticker.toUpperCase();
          const position = payload.tickers.findIndex(
            item => item.ticker === ticker.ticker
          );
          if (position >= 0) {
            payload.tickers.splice(position, 1);
            this.updateTickerType(payload);
            this.$emit("confirmTicker", payload);
          }
        });
    }
  }
};
</script>

<style></style>
